import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import Button from "@mui/material/Button";
import React from "react";

const EditBeforeGenerateButton = ({
  setEditMode,
  editMode,
  newData,
  oldData,
  setData,
  setEditDataToGen,
}) => {
  const handleConfirmEdit = () => {
    setData((old) => ({ data: { ...old.data, ...newData } }));
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditDataToGen(oldData);
    setEditMode(false);
  };

  return (
    <>
      {!editMode ? (
        <Button
          sx={{ ml: "20px" }}
          size="small"
          variant="contained"
          color="success"
          onClick={() => setEditMode(true)}
        >
          Edit
        </Button>
      ) : (
        <>
          <Button
            sx={{ ml: "20px" }}
            size="small"
            variant="contained"
            color="success"
            onClick={handleConfirmEdit}
          >
            <DoneOutlineIcon fontSize="small" />
          </Button>
          <Button
            sx={{ ml: "20px" }}
            size="small"
            variant="contained"
            color="success"
            onClick={handleCancelEdit}
          >
            <CancelOutlinedIcon fontSize="small" />
          </Button>
        </>
      )}
    </>
  );
};

export default EditBeforeGenerateButton;
