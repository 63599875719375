import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Button,
    Divider,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'

const isMobile = window.matchMedia("(max-width: 600px)").matches;

export default function LandingHeader({ loggedIn }) {
    const navigate = useNavigate()
    const feedbackUrl = 'https://forms.gle/jyn36erZEj2hynYj8';

    const handleFeedbackClick = () => {
        window.open(feedbackUrl, '_blank');
    };

    const loginOnClick = () => {
        navigate('/login')
    }
    const signUpOnClick = () => {
        navigate('/signup')
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar
                elevation={0}
                position='sticky'
                style={{ backgroundColor: "#ebf5fc", color: 'black' }}
            >
                <Toolbar>
                    {/* todo: add code along icon */}
                    {/* <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
          >
          </IconButton> */}
                    <Typography
                        variant='h6'
                        component='div'
                        sx={{ flexGrow: 1, fontWeight: 600 }}
                    >
                        LinkedIn Creator
                    </Typography>
                    <Button
                        onClick={handleFeedbackClick}
                        sx={{
                            margin: '10px',
                            color: 'black',
                            paddingRight: '20px',
                            paddingLeft: '20px',
                            ml: 'auto',
                            border: '0px solid black',
                            ':hover': {
                                border: '1px solid black',
                            },
                        }}
                    >
                        Contact Us
                    </Button>

                </Toolbar>
            </AppBar>
            <Divider />
        </Box>
    )
}
