import React, { useState } from 'react'
import axios from 'axios'

import {
    Box,
    Typography,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
} from '@mui/material'

import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import {
    CheckCircleOutline,
    HelpOutline,
    InsertDriveFile,
    PieChartOutlined,
    AccessTime,
    FormatListNumberedRtl,
    Games,
    Email,
    BugReport,
    Widgets,
} from '@mui/icons-material'

// todo: export to another file
const features = [
    {
        id: '6446643191f4f5d887272860',
        name: 'LinkedIn Profile Integration',
        description: 'Seamlessly integrate with LinkedIn to create and enhance your professional profile.',
    },
    {
        id: '6446643291f4f5d887272861',
        name: 'Resume Import',
        description: 'Import your existing resume to easily populate your LinkedIn profile.',
    },
    {
        id: '6446643291f4f5d887272862',
        name: 'Professional Networking',
        description: 'Effortlessly connect with colleagues and peers through our networking features.',
    },
    {
        id: '6446643291f4f5d887272863',
        name: 'Skills and Endorsements',
        description: 'Highlight your skills and expertise, and receive endorsements from your network.',
    },
    {
        id: '6446643291f4f5d887272864',
        name: 'Profile Optimization Suggestions',
        description: 'Receive guidance and recommendations to optimize your LinkedIn profile.',
    },
];




const Footer = () => {
    const [open, setOpen] = useState(false)
    const [openReport, setOpenReport] = useState(false)
    const [description, setDescription] = useState('')

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value)
    }

    const handleSubmitReport = async () => {
        window.open('https://forms.gle/jyn36erZEj2hynYj8', "_blank");
        handleCloseReport()
    }

    const handleClickOpen = () => {
        // reportClick(buttonsIds.features)
        setOpen(true)
    }

    const handleOpenDialog = () => {
        setOpenReport(true)
    }

    const handleCloseReport = () => {
        setOpenReport(false)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleLike = (id) => {
        // reportClick(id)
    }

    const handleContactUs = () => {
        // reportClick(buttonsIds.contactUs)
        window.location.href =
            'https://mail.google.com/mail/?view=cm&fs=1&to=resoomTech@gmail.com'
    }

    return (
        <>
            <Box sx={{ bgcolor: '#24273D', p: 3, mt: '10px' }} component='footer'>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'center', sm: 'flex-start' },
                    }}
                >
                    <Box
                        sx={{ flexGrow: 1, flexBasis: '25%', m: { xs: '0', sm: '1rem' } }}
                    >
                        <Typography
                            color='white'
                            variant='h6'
                            gutterBottom
                            sx={{ fontWeight: '700', mt: 3 }}
                        >
                            Product
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            align='left'
                            color='white'
                            component='p'
                        >
                            Our product helps you generate exams easily and efficiently.
                        </Typography>
                        <Button
                            sx={{ textTransform: 'capitalize', mt: 2 }}
                            variant='contained'
                            startIcon={<Widgets />}
                            onClick={handleClickOpen}
                        >
                            What's Next
                        </Button>
                    </Box>
                    <Box
                        sx={{ flexGrow: 1, flexBasis: '25%', m: { xs: '0', sm: '1rem' } }}
                    >
                        <Typography
                            color='white'
                            variant='h6'
                            gutterBottom
                            sx={{ fontWeight: '700', mt: 3 }}
                        >
                            Feedback
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            align='left'
                            color='white'
                            component='p'
                        >
                            We read every piece of feedback, and take your input very seriously.
                        </Typography>
                        <Button
                            sx={{ textTransform: 'capitalize', mt: 2 }}
                            variant='contained'
                            startIcon={<BugReport />}
                            onClick={handleSubmitReport}
                        >
                            Give Feedback
                        </Button>
                    </Box>
                    <Box
                        sx={{ flexGrow: 1, flexBasis: '25%', m: { xs: '0', sm: '1rem' } }}
                    >
                        <Typography
                            color='white'
                            variant='h6'
                            gutterBottom
                            sx={{ fontWeight: '700', mt: 3 }}
                        >
                            Support
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            align='left'
                            color='white'
                            component='p'
                        >
                            Need help with our product? Contact us for assistance!
                        </Typography>
                        <Button
                            sx={{ textTransform: 'capitalize', mt: 2 }}
                            variant='contained'
                            startIcon={<Email />}
                            onClick={handleContactUs}
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '15px' }}>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Future Features</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    minWidth: '40vw',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {features.map((feature) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mt: '30px',
                                            justifyContent: 'space-between',
                                        }}
                                        key={feature.id}
                                    >
                                        <div>
                                            <Typography variant='h6'>{feature.name}</Typography>
                                            <Typography variant='subtitle2'>
                                                {feature.description}
                                            </Typography>
                                        </div>
                                        <IconButton
                                            onClick={() => handleLike(feature.id)}
                                            sx={{ marginLeft: '30px' }}
                                        >
                                            <ThumbUpIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                ;
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: '15px' }}>
                    <Dialog open={openReport} onClose={handleCloseReport}>
                        <DialogTitle>Report A Bug</DialogTitle>
                        <DialogContent>
                            <Box
                                sx={{
                                    minWidth: '40vw',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <DialogContent>
                                    <h4>
                                        We read every piece of feedback, and take your input very
                                        seriously
                                    </h4>
                                    <TextField
                                        placeholder='Description...'
                                        multiline
                                        rows={5}
                                        variant='outlined'
                                        fullWidth
                                        value={description}
                                        onChange={handleDescriptionChange}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleSubmitReport}
                                        color='error'
                                        variant='contained'
                                    >
                                        Submit Bug
                                    </Button>
                                </DialogActions>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Box>
            </Box>
        </>
    )
}

export default Footer
