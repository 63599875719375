import { Box, Button, Typography } from "@mui/material";
import React from "react";

const SuggestedUsers = ({ connections }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5" component="h2" mb={2}>
        Pages To Follow
      </Typography>
      {connections.map((user) => (
        <Box
          key={user.name}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {/* <Avatar src={user.pictureLink} sx={{ width: 35, height: 35 }} /> */}
            <Box>
              <Typography variant="subtitle1" component="h3">
                {user.name}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Business Page
              </Typography>
            </Box>
          </Box>
          <Button variant="outlined" href={user.profileLink} target="_blank">
            Follow
          </Button>
        </Box>
      ))}
    </Box>
  );
};

export default SuggestedUsers;
