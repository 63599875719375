import React from "react";
import { Box } from "@mui/material";
import SuggestedUsers from "./SuggestedUsers";
import SuggestedPosts from "./SuggestedPosts";

const Suggestion = ({ posts, connections }) => {
  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <SuggestedUsers connections={connections} />
      <SuggestedPosts posts={posts} />
    </Box>
  );
};

export default Suggestion;
