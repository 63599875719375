import { useMemo } from "react";

const durationOfWorkCalculation = (startDate, endDate) => {
  if (startDate && endDate) {
    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();

    if (monthDiff < 0) {
      yearDiff--;
      monthDiff = 12 + monthDiff;
    }

    const durationOfWork = `${yearDiff === 0 ? "" : `${yearDiff} Year`}${
      yearDiff > 1 ? "s" : ""
    }${
      monthDiff === 0
        ? ""
        : `${yearDiff === 0 ? "" : " and "}${monthDiff} month`
    }${monthDiff > 1 ? "s" : ""}`;

    return durationOfWork;
  } else {
    return "";
  }
};

export const useDurationOfWork = (startDate, endDate) => {
  // useMemo that render the time difference between start and end date.
  // plus it is saves the time difference in the userProfile state in userProfile.durationOfWork
  return useMemo(() => {
    return durationOfWorkCalculation(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate]);
};

export const useDurationOfWorkArr = (arr) => {
  return useMemo(
    () =>
      arr.map((elm) => durationOfWorkCalculation(elm.startDate, elm.endDate)),
    [arr]
  );
};
