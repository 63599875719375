import { Box, Button, Chip, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import { personalSkills, skills } from "../utils/options";
import CustomDialog from "./CustomDialog";
import EditBeforeGenerateButton from "./EditBeforeGenerateButton";

const SkillsSection = ({ about, topSkills, setData }) => {
  const [editAboutMode, setEditAboutMode] = useState(false);
  const [editAboutData, setEditAboutData] = useState(about);
  const [editSkillsData, setEditSkillsData] = useState(topSkills);
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);

  if (topSkills.length > 8) topSkills.length = 8; // display only 5 skills

  // editMode - can delete by pressing x btn on the tag
  const deleteSkillByIndex = (idx) => {
    const skillsCopy = [...editSkillsData];
    skillsCopy.splice(idx, 1);
    setEditSkillsData(skillsCopy);
  };

  // editMode - pick skills while pressing on the checkboxes in CustomDialog
  const handleSkillSelected = (event) => {
    const skillName = event.target.name;
    const newSelectedSkills = event.target.checked
      ? [...editSkillsData, { name: skillName, rating: 5 }]
      : editSkillsData.filter((skill) => skill.name !== skillName);
    setEditSkillsData(newSelectedSkills);
  };

  // editMode - find the rating value cause CustomDialog hanling only simple arrays and not arrays of objects
  const findRating = (skillName) => {
    const result = editSkillsData.find((obj) => obj.name === skillName);
    if (result) {
      return result.rating;
    } else {
      return "1";
    }
  };

  // editMode - AutoComplete handler - if select new skill - it will generate the rating with value of "1"
  const handleCustomDialogAutoComplete = (value) => {
    const formatSkills = value.map((skillName) => ({
      name: skillName,
      rating: findRating(skillName),
    }));
    setEditSkillsData(formatSkills);
  };

  // return to the origin data before editing
  const handleEditDataBackToGeneraterd = (data) => {
    const { about, skills } = data;
    setEditAboutData(about);
    setEditSkillsData(skills);
  };

  // formatting the complex array of objects to a simple array so the CustomDialog will work properly
  const skillsWithNoRating = useMemo(() => {
    const skillsWithNoRating = [];
    editSkillsData.forEach((skill) => {
      skillsWithNoRating.push(skill.name);
    });
    return skillsWithNoRating;
  }, [editSkillsData]);

  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <Typography variant="h5" component="h2" mb={2}>
        Top Skills
        <EditBeforeGenerateButton
          setEditMode={setEditAboutMode}
          editMode={editAboutMode}
          newData={{ about: editAboutData, skills: editSkillsData }}
          oldData={{ about, skills: topSkills }}
          setData={setData}
          setEditDataToGen={handleEditDataBackToGeneraterd}
        />
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          maxWidth: "100vw",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        {!editAboutMode ? (
          topSkills.length > 0 ? (
            topSkills.map((skill) => (
              <Chip key={skill.name} label={skill.name} />
            ))
          ) : (
            <Typography>No Skills...</Typography>
          )
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setToolsDialogOpen(true)}
            >
              Skills +
            </Button>
            <CustomDialog
              title={"Your Personal/Tech Skills"}
              open={toolsDialogOpen}
              onClose={() => setToolsDialogOpen(false)}
              elements={[...skills, ...personalSkills]}
              selectedTools={skillsWithNoRating}
              handleElementSelected={handleSkillSelected}
              onChangeAutoComplete={handleCustomDialogAutoComplete}
              valueAutoComplete={skillsWithNoRating}
              placeholder={"Search Your Personal And Tech Skills Here"}
              label={"Your Personal/Tech Skills"}
            />
            {editSkillsData.map((skill, idx) => (
              <Chip
                key={skill.name}
                label={skill.name}
                onDelete={() => deleteSkillByIndex(idx)}
              />
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default SkillsSection;
