import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import InputPage from "./pages/Input";
import ProfilePage from "./pages/Profile";
import LandingPage from "./pages/LandingPage";

import { ProfileProvider } from "./contexts/profileContextProvider";

function App() {
  return (
    <ProfileProvider>
      <BrowserRouter>
        <Routes>
          <Route index path="/" Component={LandingPage} />
          <Route exect path="/profile" Component={ProfilePage} />
          <Route exact path="/input" Component={InputPage} />
        </Routes>
      </BrowserRouter>
    </ProfileProvider>
  );
}

export default App;
