import React, { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import CopyToClipboardButton from "../components/CopyToClipboardButton ";
import EditBeforeGenerateButton from "./EditBeforeGenerateButton";

const HeadlineSection = ({ headline, setData }) => {
  const [editHeadlineMode, setEditHeadlineMode] = useState(false);
  const [editHeadlineData, setEditHeadlineData] = useState(headline);

  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <Typography variant="h5" component="h2" mb={2}>
        Headline
        <CopyToClipboardButton value={headline} editMode={editHeadlineMode} />
        <EditBeforeGenerateButton
          setEditMode={setEditHeadlineMode}
          editMode={editHeadlineMode}
          newData={{ headLine: editHeadlineData }}
          oldData={headline}
          setData={setData}
          setEditDataToGen={setEditHeadlineData}
        />
      </Typography>
      {!editHeadlineMode ? (
        <Typography variant="body1" color="text.secondary" mb={2}>
          {headline ? headline : "Missing Data"}
        </Typography>
      ) : (
        <TextField
          label="Headline"
          value={editHeadlineData}
          fullWidth
          onChange={(e) => setEditHeadlineData(e.target.value)}
          required
          multiline
          rows={3}
          inputProps={{ maxLength: 510 }}
        />
      )}
    </Box>
  );
};

export default HeadlineSection;
