import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import CopyToClipboardButton from "./CopyToClipboardButton ";
import EditBeforeGenerateButton from "./EditBeforeGenerateButton";

const AboutSection = ({ about, topSkills, setData }) => {
  const [editAboutMode, setEditAboutMode] = useState(false);
  const [editAboutData, setEditAboutData] = useState(about);
  const [editSkillsData, setEditSkillsData] = useState(topSkills);

  // return to the origin data before editing
  const handleEditDataBackToGeneraterd = (data) => {
    const { about, skills } = data;
    setEditAboutData(about);
    setEditSkillsData(skills);
  };

  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <Typography variant="h5" component="h2" mb={2}>
        About
        <CopyToClipboardButton value={about} editMode={editAboutMode} />
        <EditBeforeGenerateButton
          setEditMode={setEditAboutMode}
          editMode={editAboutMode}
          newData={{ about: editAboutData, skills: editSkillsData }}
          oldData={{ about, skills: topSkills }}
          setData={setData}
          setEditDataToGen={handleEditDataBackToGeneraterd}
        />
      </Typography>
      {!editAboutMode ? (
        <Typography variant="body1" color="text.secondary" mb={2}>
          {about ? about : "Missing Data"}
        </Typography>
      ) : (
        <TextField
          label="About"
          value={editAboutData}
          fullWidth
          onChange={(e) => setEditAboutData(e.target.value)}
          required
          multiline
          rows={3}
          inputProps={{ maxLength: 510 }}
        />
      )}
    </Box>
  );
};

export default AboutSection;
