import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Avatar,
  Typography,
  Button,
} from "@mui/material";
import BulletList from "./BulletList";
import { steps } from "../utils/moreInfoSteps";

const MoreInfo = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(true);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      saveCookie();
      handleClose();
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    deleteCookie();
  };
  const handleClose = () => setOpenDialog(false);

  const saveCookie = () => {
    if (activeStep === steps.length - 1) {
      sessionStorage.setItem("examCreated", true);
    }
  };

  function deleteCookie() {
    sessionStorage.removeItem("examCreated");
  }

  useEffect(() => {
    const examCreated = sessionStorage.getItem("examCreated");
    if (examCreated) {
      handleClose();
    }
  }, []);

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>Let's Get Started 🎉</DialogTitle>
      <DialogContent
        sx={{
          width: "88%",
          height: { xs: "70vh", sm: "80vh" },
        }}
      >
        <Stack spacing={3}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {steps[activeStep].avatar && (
              <Avatar
                src={steps[activeStep].avatar}
                sx={{ height: "100px", width: "100px" }}
              />
            )}
            <Typography variant="h5">{steps[activeStep].subtitle}</Typography>
            <BulletList text={steps[activeStep].content}></BulletList>
            <div style={{ marginTop: "40px" }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{ marginRight: "1rem" }}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? "Create LinkedIn" : "Next"}
              </Button>
            </div>
          </div>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MoreInfo;
