export const skills = [
  "Angular",
  "AWS",
  "Bash",
  "Business Intelligence (BI) tools",
  "C++",
  "CSS",
  "Docker",
  "Git",
  "GraphQL",
  "HTML",
  "Java",
  "JavaScript",
  "Jupyter Notebook",
  "JSON",
  "Keras",
  "Linux",
  "Matplotlib",
  "MongoDB",
  "NoSQL",
  "Node.js",
  "NumPy",
  "OAuth",
  "Pandas",
  "PHP",
  "Python",
  "React",
  "Redux",
  "RESTful APIs",
  "Ruby",
  "Scala",
  "Scikit-learn",
  "Seaborn",
  "SPSS",
  "SQL",
  "Swift",
  "TensorFlow",
  "TypeScript",
  "Vue.js",
  "XML",
  "Agile development",
  "Ansible",
  "Apache Kafka",
  "Blockchain",
  "C#",
  "CI/CD",
  "Data warehousing",
  "DevOps",
  "Elasticsearch",
  "Golang",
  "Hadoop",
  "Ionic",
  "Jenkins",
  "Kubernetes",
  "Machine learning",
  "Microservices architecture",
  "Natural Language Processing (NLP)",
  "PyTorch",
  "Rust",
  "Serverless computing",
];

export const personalSkills = [
  "Active Listening",
  "Adaptability",
  "Attention to Detail",
  "Collaboration",
  "Communication",
  "Coaching and Mentoring",
  "Conflict Resolution",
  "Creativity",
  "Decision Making",
  "Emotional Intelligence",
  "Empathy",
  "Flexibility",
  "Initiative",
  "Interpersonal Skills",
  "Leadership",
  "Organization",
  "Public Speaking",
  "Resilience",
  "Strategic Thinking",
  "Time Management",
  "Critical Thinking",
  "Customer Service",
  "Dependability",
  "Empowerment",
  "Entrepreneurial Spirit",
  "Ethics and Integrity",
  "Goal Setting",
  "Innovation",
  "Judgment",
  "Learning Agility",
  "Networking",
  "Open-mindedness",
  "Positive Attitude",
  "Problem Solving",
  "Project Management",
  "Relationship Building",
  "Self-Motivation",
  "Team Building",
  "Technical Skills",
  "Visionary Thinking",
];

export const topDegrees = [
  "Accounting",
  "Biology",
  "Business Administration",
  "Civil Engineering",
  "Computer Science",
  "Economics",
  "Education",
  "Electrical Engineering",
  "Environmental Science",
  "Finance",
  "Graphic Design",
  "Human Resources",
  "Information Technology",
  "Law",
  "Marketing",
  "Media Studies",
  "Mechanical Engineering",
  "Nursing",
  "Political Science",
  "Psychology",
  "Architecture",
  "Chemical Engineering",
  "Chemistry",
  "Communications",
  "Criminal Justice",
  "Dentistry",
  "Fashion Design",
  "Geography",
  "History",
  "Hospitality Management",
  "Industrial Design",
  "Journalism",
  "Linguistics",
  "Mathematics",
  "Music",
  "Nutrition",
  "Philosophy",
  "Physics",
  "Social Work",
  "Sociology",
  "Aerospace Engineering",
  "Agricultural Engineering",
  "Architectural Engineering",
  "Automotive Engineering",
  "Biological Engineering",
  "Chemical and Biomolecular Engineering",
  "Computer Engineering",
  "Construction Engineering",
  "Environmental Engineering",
  "Geological Engineering",
  "Industrial Engineering",
  "Manufacturing Engineering",
  "Materials Science and Engineering",
  "Mining Engineering",
  "Nuclear Engineering",
  "Petroleum Engineering",
  "Robotics Engineering",
];

export const topUniversities = [
  "Ariel University",
  "Bar-Ilan University",
  "Bezalel Academy of Arts and Design",
  "Ben-Gurion University of the Negev",
  "Coding Academy",
  "HackerU",
  "Hebrew University of Jerusalem",
  "Holon Institute of Technology",
  "Infinity Labs",
  "Interdisciplinary Center Herzliya",
  "Jerusalem College of Engineering",
  "Jerusalem College of Technology",
  "Reichman University",
  "Ruppin Academic Center",
  "Sapir College",
  "Self Learning",
  "Shenkar College of Engineering, Design and Art",
  "Tel Aviv University",
  "The Academic College of Tel Aviv-Yaffo",
  "The College of Management",
  "The Open University of Israel",
  "Technion - Israel Institute of Technology",
  "University of Haifa",
  "University of Tel Aviv",
  "Weizmann Institute of Science",
  "Wingate Institute",
  "Harvard University",
  "Massachusetts Institute of Technology (MIT)",
  "Stanford University",
  "University of Cambridge",
  "University of Oxford",
  "California Institute of Technology (Caltech)",
  "University of Chicago",
  "Princeton University",
  "Yale University",
  "Columbia University",
  "University of Pennsylvania",
  "Cornell University",
  "Johns Hopkins University",
  "Duke University",
  "University of California, Berkeley",
  "University of California, Los Angeles (UCLA)",
  "University of Michigan",
  "University of Toronto",
  "ETH Zurich - Swiss Federal Institute of Technology",
  "University of Tokyo",
];
