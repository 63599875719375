import React, { useState } from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ExperienceItem from "./ExperienceItem";
import EditBeforeGenerateButton from "./EditBeforeGenerateButton";
import { cloneDeep } from "lodash";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const ExperienceSection = ({ exp, setData }) => {
  const [editExpMode, setEditExpMode] = useState(false);
  const [editExpData, setEditExpData] = useState(exp);

  const handleExpChange = (idx) => (e) => {
    const expCopy = cloneDeep(editExpData);
    if (e.target) {
      expCopy[idx][e.target.name] = e.target.value;
    } else {
      const date = e.date;
      expCopy[idx][e.name] = date;
    }

    setEditExpData(expCopy);
  };

  const handleDeleteExp = (idx) => {
    const expCopy = [...editExpData];
    expCopy.splice(idx, 1);

    setEditExpData(expCopy);
  };

  const handleAddExp = () => {
    setEditExpData((old) => [
      ...old,
      // TODO: export it to a model
      {
        currentJobDescription: "",
        companyName: "",
        achievements: "",
        startDate: null,
        endDate: null,
        description: "",
        techSkills: [],
      },
    ]);
  };

  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <Typography variant="h5" component="h2" mb={2}>
        Experience
        <EditBeforeGenerateButton
          setEditMode={setEditExpMode}
          editMode={editExpMode}
          newData={{ jobExperience: editExpData }}
          oldData={exp}
          setData={setData}
          setEditDataToGen={setEditExpData}
        />
      </Typography>
      <>
        {editExpData.map((e, idx) => (
          // TODO: use nanoid lib for avoiding react rendering problems
          <div key={idx}>
            <ExperienceItem
              editExpMode={editExpMode}
              setEditExpData={handleExpChange(idx)}
              deleteExp={() => handleDeleteExp(idx)}
              disabledDelete={editExpData.length === 1}
              title={e.title}
              company={e.companyName}
              startDate={e.startDate}
              endDate={e.endDate}
              description={e.description}
              // TODO: delete all the e.[data] and use only data prop
              data={e}
            />
            {editExpData.length !== 1 && editExpData.length - 1 !== idx && (
              <Divider sx={{ marginBottom: 2 }} />
            )}
          </div>
        ))}
        {editExpMode && (
          <IconButton
            onClick={handleAddExp}
            disabled={editExpData.length === 20}
          >
            <AddCircleIcon />
          </IconButton>
        )}
      </>
    </Box>
  );
};

export default ExperienceSection;
