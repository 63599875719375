import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { cloneDeep } from "lodash";
import EditBeforeGenerateButton from "./EditBeforeGenerateButton";

const EducationSection = ({ education, setData }) => {
  const [editEduMode, setEditEduMode] = useState(false);
  const [editEduData, setEditEduData] = useState(education);

  const handleEduChange = (idx, e) => {
    const eduCopy = cloneDeep(editEduData);
    if (e.target) {
      eduCopy[idx][e.target.name] = e.target.value;
    } else {
      const date = e.date;
      eduCopy[idx][e.name] = date;
    }

    setEditEduData(eduCopy);
  };

  const handleDeleteEdu = (idx) => {
    const eduCopy = [...editEduData];
    eduCopy.splice(idx, 1);

    setEditEduData(eduCopy);
  };

  const handleAddEdu = () => {
    setEditEduData((old) => [
      ...old,
      // TODO: export it to a model
      {
        institution: "",
        degreeType: "",
      },
    ]);
  };

  return (
    <Box sx={{ mb: 3, width: "100%" }} className="profile-sections">
      <Typography variant="h5" component="h2" mb={2}>
        Education
        <EditBeforeGenerateButton
          setEditMode={setEditEduMode}
          editMode={editEduMode}
          newData={{ education: editEduData }}
          oldData={education}
          setData={setData}
          setEditDataToGen={setEditEduData}
        />
      </Typography>
      <>
        {editEduData.map((edu, idx) => (
          // TODO: use nanoid lib for avoiding react rendering problems

          <Box sx={{ mb: 2 }} key={idx}>
            {!editEduMode ? (
              <>
                <Typography variant="h6" component="h3" mb={1}>
                  {`institution name - ${edu.institution}`}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" mb={1}>
                  {`Degree Type - ${edu.degreeType}`}
                </Typography>
              </>
            ) : (
              <Grid container alignItems="center">
                <Grid
                  item
                  container
                  alignItems="center"
                  sx={{ gap: 2, mb: 2 }}
                  xs={11}
                >
                  <TextField
                    label="Title"
                    name="institution"
                    value={edu.institution}
                    fullWidth
                    onChange={(e) => handleEduChange(idx, e)}
                    required
                    inputProps={{ maxLength: 50 }}
                  />
                  <TextField
                    label="Company"
                    name="degreeType"
                    value={edu.degreeType}
                    fullWidth
                    onChange={(e) => handleEduChange(idx, e)}
                    required
                    inputProps={{ maxLength: 50 }}
                  />
                </Grid>
                <Grid item container alignItems="center" sx={{ gap: 2 }} xs={1}>
                  <IconButton
                    onClick={() => handleDeleteEdu(idx)}
                    disabled={editEduData.length === 1}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}

            {editEduData.length !== 1 && editEduData.length - 1 !== idx && (
              <Divider sx={{ marginBottom: 2 }} />
            )}
          </Box>
        ))}
        {editEduMode && (
          <IconButton
            onClick={handleAddEdu}
            disabled={editEduData.length === 20}
          >
            <AddCircleIcon />
          </IconButton>
        )}
      </>
    </Box>
  );
};

export default EducationSection;
