import React, { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { ProfileContext } from "../contexts/profileContextProvider";

const UploadProfilePicture = ({
  displayOnly = false,
  width = 56,
  height = 56,
}) => {
  const { setProfilePicture, profilePicture } = useContext(ProfileContext);
  const handleProfilePictureChange = (event) => {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function () {
      setProfilePicture(reader.result.split(",")[1]);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const handleProfilePictureRemove = () => {
    setProfilePicture(null);
  };

  const loadEditableDisplay = () => {
    return (
      <>
        <input
          accept="image/*"
          type="file"
          onChange={handleProfilePictureChange}
          id="profile-picture-input"
          style={{ display: "none" }}
          maxSize={20 * 1024 * 1024} // set the maximum size to 20MB
        />
        <label htmlFor="profile-picture-input">
          <Button variant="contained" color="secondary" component="span">
            Upload
          </Button>
        </label>
        {profilePicture && (
          <Button variant="outlined" onClick={handleProfilePictureRemove}>
            Remove
          </Button>
        )}
      </>
    );
  };
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Avatar
        alt="Profile"
        src={"data:image/jpg;base64, " + profilePicture}
        sx={{ width, height }}
      />
      {displayOnly || loadEditableDisplay()}
    </Stack>
  );
};

export default UploadProfilePicture;
