import { Button, DialogActions, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";

const LSKDatePicker = ({
  value,
  label,
  onChange,
  minDate,
  maxDate,
  disableFuture,
  openTo,
  views,
  closeOnSelect,
  fullWidth,
  error,
  disabled,
  ...props
}) => {
  function CustomActionBar(props) {
    const { className, onClear, onSetToday } = props;
    if (label !== "Start Date")
      return (
        <DialogActions className={className}>
          <Button
            onClick={(e) => {
              onClear();
              onChange(null, true);
            }}
          >
            Still work
          </Button>
          <Button
            onClick={(e) => {
              onSetToday();
            }}
          >
            Today
          </Button>
        </DialogActions>
      );
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          sx={{
            width: fullWidth ? "100%" : "unset",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: !!error ? "#D32F2F" : "none",
            },
          }}
          disabled={disabled}
          label={label}
          value={value}
          onChange={(date) => {
            onChange(date);
          }}
          // TODO: check for alternetive to renderInput prop if needed
          // renderInput={(params) => <TextField {...params} />}
          minDate={minDate || null}
          maxDate={maxDate || null}
          disableFuture={disableFuture}
          closeOnSelect={closeOnSelect}
          openTo={openTo}
          views={views}
          slots={{
            actionBar: CustomActionBar,
          }}
          slotProps={{
            actionBar: {
              actions: ["clear", "today"],
            },
          }}
          {...props}
        />
      </LocalizationProvider>

      <Typography sx={{ color: "#D32F2F", minHeight: 24 }}>
        {!!error && error}
      </Typography>
    </>
  );
};

export default LSKDatePicker;
