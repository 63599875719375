import React, { useState } from "react";
import Button from "@mui/material/Button";

const CopyToClipboardButton = (props) => {
  const { value } = props;
  const [copied, setCopied] = useState(false);

  const label = copied ? "Copied!" : "Copy";

  const handleClick = () => {
    if (!navigator.clipboard) {
      // fallback for browsers that do not support the Clipboard API
      const textArea = document.createElement("textarea");
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      setCopied(true);
      return;
    }

    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  return (
    <>
      {!props.editMode && (
        <Button
          sx={{ ml: "20px" }}
          size="small"
          variant="contained"
          color="success"
          onClick={handleClick}
        >
          {label}
        </Button>
      )}
    </>
  );
};

export default CopyToClipboardButton;
