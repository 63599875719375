import gradeGif from "../assets/more-info/grade.gif";
import pdfGif from "../assets/more-info/pdf.gif";

export const steps = [
  {
    avatar: pdfGif,
    title: "Create Profile",
    subtitle: "Use AI for LinkedIn",
    content:
      'Enter your basic information, such as name, headline, and summary.\nCustomize your profile with additional information and media using AI-powered suggestions.\nClick "GENERATE PROFILE" to see the AI-generated profile.',
  },
  {
    avatar: gradeGif,
    title: "Enhance",
    subtitle: "Make your skills stand out",
    content:
      "Choose from AI-generated suggestions to add relevant skills to your profile.\nShowcase your expertise by adding endorsements and recommendations from colleagues.",
  },
  {
    avatar: pdfGif,
    title: "Publish",
    subtitle: "Publish LinkedIn profile",
    content:
      "After reviewing and editing your profile, publish to LinkedIn.\nUse your new AI-generated profile to enhance your professional online presence.",
  },
];
