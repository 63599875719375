import { Autocomplete, TextField, Typography } from "@mui/material";
import React from "react";

export default function LpcAutoComplete({
  label,
  name,
  error,
  value,
  fullWidth,
  onChange,
  required,
  inputProps,
  onBlur,
  countDown,
  options,
  disabled,
  ...props
}) {
  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: !!error ? "#D32F2F" : "none",
          },
        }}
        disabled={disabled}
        value={value}
        disablePortal
        options={options}
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={onChange}
        {...props}
      />

      <Typography sx={{ color: "#D32F2F", minHeight: 24 }}>
        {!!error && error}
      </Typography>
    </>
  );
}
