import { array, date, object, string, ref } from "yup";
import { regexExpressions } from "./regex";
import {
  extraStepFields,
  personalStepFields,
  professionalStepFields,
} from "../utils/models";

export const inputValidations = async (data, setErrors, context) => {
  const personalDetailsSchema = object().shape({
    // PersonalStep
    fullName: string()
      .matches(
        regexExpressions.noSignsAndDigits,
        "Digits/Signs/Non-English language found here 😊"
      )
      .required("Name is required"),

    aboutMe: string().required("About me is required"),

    // ProfessionalStep
    jobExperience: array().of(
      object().when("$isUnemployeed", ([isUnemployeed], schema) =>
        isUnemployeed
          ? schema
          : schema.shape({
              currentJobDescription: string()
                .matches(
                  regexExpressions.noSignsAndDigits,
                  "Digits/Signs/Non-English language found here 😊"
                )
                .required("this field is required currentJobDescription"),

              companyName: string().required("this field is required"),

              startDate: date().required("this field is required"),

              endDate: date()
                .min(ref("startDate"), "end date can't be before start date")
                .required("this field is required"),
            })
      )
    ),

    education: array().of(
      object().when("$isNotEducate", ([isNotEducate], schema) =>
        isNotEducate
          ? schema
          : schema.shape({
              // TODO: add to studiesName and degreeName a free text
              studiesName: string().required("this field is required"),

              degreeName: string().required("this field is required"),
            })
      )
    ),
  });

  // validation proccess
  personalDetailsSchema
    .validate(data, {
      abortEarly: false,
      context,
    })
    .then(() => {
      setErrors({
        personalErrors: {},
        professionalErrors: {},
        extraErrors: {},
      });
    })
    .catch((err) => {
      const errors = {
        personalErrors: {},
        professionalErrors: {},
        extraErrors: {},
      };

      err.inner.forEach((validationError) => {
        if (personalStepFields.indexOf(validationError.path) > -1) {
          errors.personalErrors = {
            ...errors.personalErrors,
            [validationError.path]: validationError.message,
          };
        } else if (professionalStepFields.indexOf(validationError.path) > -1) {
          errors.professionalErrors = {
            ...errors.professionalErrors,
            [validationError.path]: validationError.message,
          };
        } else if (
          professionalStepFields.some((key) =>
            validationError.path.includes(key)
          )
        ) {
          errors.professionalErrors = {
            ...errors.professionalErrors,
            // format the error inside the arr the way we want
            [`${validationError.path.split(".")[1]}[${
              validationError.path.match(/\d+/)[0]
            }]`]: validationError.message,
          };
        } else if (extraStepFields.indexOf(validationError.path) > -1) {
          errors.extraErrors = {
            ...errors.extraErrors,
            [validationError.path]: validationError.message,
          };
        }
      });
      setErrors(errors);
    });
};

export const pressedNextValidationError = (data) => {
  if (data.personalSkills.length === 0) return true;
};

export const activeStepToErrorsStep = {
  0: "personalErrors",
  1: "professionalErrors",
  2: "extraErrors",
};
