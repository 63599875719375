import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { personalSkills } from "../utils/options";
import CustomDialog from "./CustomDialog";
import LSKOptions from "./LSKOptions";
import LpcTexptField from "./LpcTexptField";
import UploadProfilePicture from "./UploadProfilePicture";

const PersonalStep = ({
  userProfile,
  setUserProfile,
  handleKeyDown,
  deleteSkillByIndex,
  handleCustomDialogAutoComplete,
  handleSkillSelected,
  errors,
  touch,
  setTouch,
}) => {
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);

  return (
    <Grid container spacing={2}>
      <h2>Your Details</h2>
      <Grid item xs={12}>
        <UploadProfilePicture />
      </Grid>
      <Grid item xs={6}>
        <LpcTexptField
          label="Full Name"
          name="fullName"
          error={touch.fullName && errors.fullName}
          value={userProfile.fullName}
          fullWidth
          onChange={(e) => setUserProfile(e, "fullName")}
          required
          onBlur={() => setTouch((old) => ({ ...old, fullName: true }))}
          countDown={20}
        />
      </Grid>
      <Grid item xs={12}>
        <LpcTexptField
          name="aboutMe"
          error={touch.aboutMe && errors.aboutMe}
          required
          onBlur={() => setTouch((old) => ({ ...old, aboutMe: true }))}
          countDown={510}
          onChange={(e) => setUserProfile(e, "aboutMe")}
          value={userProfile.aboutMe}
          placeholder="Motivated junior software developer with strong problem-solving skills and a passion for learning. Proficient in programming languages such as Java and Python, and familiar with agile development methodologies"
          label="A Few Words About Yourself"
          fullWidth
          multiline
          rows={4}
          onKeyDown={(e) => handleKeyDown(e, "aboutMe")}
        />
      </Grid>
      <Grid
        container
        alignItems="center"
        item
        xs={12}
        md={12}
        sx={{ gap: "16px", minHeight: 58 }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setToolsDialogOpen(true)}
        >
          Add Personal Skills +
        </Button>
        <LSKOptions
          arrToRender={userProfile.personalSkills}
          onDelete={deleteSkillByIndex("personalSkills")}
        />
        <CustomDialog
          title={"Your Personal Skills"}
          open={toolsDialogOpen}
          onClose={() => setToolsDialogOpen(false)}
          elements={personalSkills}
          selectedTools={userProfile.personalSkills}
          handleElementSelected={handleSkillSelected}
          onChangeAutoComplete={handleCustomDialogAutoComplete(
            "personalSkills"
          )}
          valueAutoComplete={userProfile.personalSkills}
          placeholder={"Search Your Personal Skills Here"}
          label={"Your Personal Skills"}
        />
      </Grid>
    </Grid>
  );
};

export default PersonalStep;
