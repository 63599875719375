export const fakeParsedData = {
  data: {
    headLine: "Senior Software Engineer | Angular | AWS",
    about:
      "I am a highly skilled and experienced senior software engineer with a strong background in Angular and AWS. With a proven track record of delivering high-quality software solutions, I have successfully led multiple projects and consistently exceeded client expectations. My expertise lies in developing scalable and efficient web applications, utilizing the latest technologies and best practices. I am passionate about coding and constantly strive to stay updated with the latest industry trends. In addition to my technical skills, I am also an excellent communicator and possess strong problem-solving abilities. I am currently seeking new opportunities to further enhance my skills and contribute to innovative projects.",
    education: [
      {
        institution: "Ben-Gurion University of the Negev",
        degreeType: "Computer Science",
        significantCourses: "Algorithms",
      },
      {
        institution: "Holon Institute of Technology",
        degreeType: "Economics",
        significantCourses: "",
      },
    ],
    skills: [
      {
        name: "Angular",
        rating: 5,
      },
      {
        name: "AWS",
        rating: 4,
      },
      {
        name: "Business Intelligence (BI) tools",
        rating: 3,
      },
      {
        name: "Communication",
        rating: 5,
      },
      {
        name: "Active Listening",
        rating: 4,
      },
      {
        name: "Adaptability",
        rating: 4,
      },
    ],
    experience: [
      {
        title: "Senior Software Engineer",
        companyName: "ironSource",
        description:
          "Leading the development of complex web applications using Angular and AWS. Collaborating with cross-functional teams to deliver high-quality software solutions. Mentoring junior developers and conducting code reviews.",
        startDate: "01/01/2023",
        endDate: "01/08/2023",
        techSkills: [
          {
            name: "Angular",
            rating: 5,
          },
          {
            name: "AWS",
            rating: 4,
          },
        ],
      },
      {
        title: "Junior Frontend Developer",
        companyName: "Microsoft",
        description:
          "Contributed to the development of frontend applications using Angular. Assisted in the implementation of Business Intelligence (BI) tools. Worked closely with the team to ensure timely delivery of projects.",
        startDate: "01/02/2021",
        endDate: "01/01/2023",
        techSkills: [
          {
            name: "Angular",
            rating: 5,
          },
          {
            name: "Business Intelligence (BI) tools",
            rating: 3,
          },
        ],
      },
    ],
    callToAction: "Connect with me to discuss potential job opportunities.",
    postsIdeas: [
      "Exploring the latest trends in Angular development",
      "Best practices for optimizing AWS infrastructure",
      "Case study: Implementing Business Intelligence tools for data-driven decision making",
    ],
    connections: [
      {
        name: "LinkedIn Page 1",
        profileLink: "https://www.linkedin.com/page1",
        pictureLink: "https://example.com/page1-avatar.jpg",
      },
      {
        name: "LinkedIn Page 2",
        profileLink: "https://www.linkedin.com/page2",
        pictureLink: "https://example.com/page2-avatar.jpg",
      },
      {
        name: "LinkedIn Page 3",
        profileLink: "https://www.linkedin.com/page3",
        pictureLink: "https://example.com/page3-avatar.jpg",
      },
    ],
  },
};
