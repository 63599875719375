import { useState, createContext } from "react";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profilePicture, setProfilePicture] = useState(null);
  const [isLogin, setIsLogin] = useState(
    process.env.NODE_ENV !== "production" ? true : false
  );
  return (
    <ProfileContext.Provider
      value={{ profilePicture, isLogin, setIsLogin, setProfilePicture }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
