import React, { useEffect } from "react";
import { Button, Typography } from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import axios from "axios";

function LoginPage({ setIsLogin }) {
  useEffect(() => {
    axios
      .get("/api/auth/isLoggedIn")
      .then((res) => {
        res.data.loggedIn && setIsLogin(true);
      })
      .catch(() => setIsLogin(false));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <a href="/api/auth/oauth/linkedin" style={{ textDecoration: "none" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LinkedIn />}
          size="large"
          style={{ textTransform: "capitalize" }}
        >
          Continue with LinkedIn
        </Button>
      </a>
    </div>
  );
}

export default LoginPage;
