import { Grid, TextField, Typography } from "@mui/material";
import React, { useMemo } from "react";

export default function LpcTexptField({
  label,
  name,
  error,
  value,
  fullWidth,
  onChange,
  required,
  inputProps,
  onBlur,
  countDown,
  disabled,
  ...props
}) {
  const cntRelativeToCountDown = useMemo(() => value.length, [value]);

  return (
    <>
      <TextField
        label={label}
        error={!!error}
        value={value}
        fullWidth={fullWidth}
        onChange={onChange}
        required={required}
        inputProps={{ maxLength: countDown }}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        {...props}
      />

      <Grid container justifyContent="space-between">
        <Typography sx={{ color: "#D32F2F", minHeight: 24 }}>
          {!!error && error}
        </Typography>
        {!!countDown && (
          <Typography
            sx={{ color: !!error ? "#D32F2F" : "#000000" }}
          >{`${cntRelativeToCountDown} / ${countDown}`}</Typography>
        )}
      </Grid>
    </>
  );
}
