import React, { useState } from 'react'
import { Grid, Typography, Button, Stack } from '@mui/material'
import Album from './Album'
import { useNavigate } from 'react-router-dom'
import LoginPage from '../../pages/LinkedInAuth'

const isMobile = window.matchMedia("(max-width: 600px)").matches;
const MOBILE_SPACING = 12
const DESKTOP_SPACING = 1

// TODO: move the styles to differnet file
const styles = {
    grid: {
        height: '45vh',
        width: '100%',
    },
    leftSide: {
        backgroundColor: '#ebf5fc',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        marginTop: '20px',
        maxWidth: '100%',
        maxHeight: '100%',
        '@media (minWidth:600px)': {
            maxWidth: 'unset',
            maxHeight: 'unset',
        },
        '@media (maxWidth:600px)': {
            maxHeight: '50vh',
        },
    },
    rightSide: {
        backgroundColor: "#ebf5fc",
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        maxWidth: '100%',
        maxHeight: '100%',
        '@media (minWidth:600px)': {
            maxWidth: 'unset',
            maxHeight: 'unset',
        },
        '@media (maxWidth:600px)': {
            maxHeight: '50vh',
        },
    },
    title: {
        fontWeight: '600',
        fontSize: isMobile ? '45px' : '60px',
        margin: 'auto',
        textAlign: 'center',
    },
    subTitle: {
        fontWeight: '400',
        fontSize: isMobile ? '18px' : '22px',
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
        textAlign: 'center',
    },
    button: {
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '95%',
        maxWidth: '400px',
        '@media (minWidth:600px)': {
            width: 'auto',
            maxWidth: 'unset',
        },
    },
}

const LandingPageBody = () => {
    const navigate = useNavigate()
    const [isLogin, setIsLogin] = useState(false)

    // todo: login page
    const loginOnClick = () => {
        // reportClick(buttonsIds.createAdvanceExams)
        navigate('/login')
    }

    if (isLogin) return <LoginPage setIsLogin={setIsLogin}></LoginPage>;

    return (
        <>
            {/* todo: add background image */}
            <Stack style={{ marginTop: '20px', }} spacing={isMobile ? MOBILE_SPACING : DESKTOP_SPACING}>

                {/***** Mobile Design ******/}
                {isMobile && (
                    <Stack spacing={3} alignItems={'center'} mt={7}>
                        <Typography
                            variant='h2'
                            component='h2'
                            gutterBottom
                            style={styles.title}
                        >
                            LinkedIn Profile Creator
                        </Typography>
                        <Typography
                            variant='subtitle1'
                            component='p'
                            gutterBottom
                            style={styles.subTitle}
                        >
                            <b>Generate</b> your <b>AI-powered LinkedIn</b> profile quickly and free to improve your existing one or craft a brand-new profile.                        </Typography>
                        <Grid container spacing={2} justifyContent="center">
                            <LoginPage />
                        </Grid>
                    </Stack>
                )}


                {/***** Desktop Design ******/}
                {!isMobile && (
                    <Grid container spacing={0} style={styles.grid}>
                        <Grid item xs={12} sm={12} style={styles.leftSide}>
                            <Stack
                                spacing={1}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant='h2'
                                    component='h2'
                                    gutterBottom
                                    style={styles.title}
                                >
                                    LinkedIn Profile Creator
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='p'
                                    gutterBottom
                                    style={styles.subTitle}
                                >
                                    <b>Generate</b> your <b>AI-powered LinkedIn</b> profile quickly and free to improve your existing one or craft a brand-new profile.
                                </Typography>
                                <Grid container spacing={2} justifyContent="center">
                                    <LoginPage setIsLogin={setIsLogin} />
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                )}
                <Album></Album>
            </Stack >
        </>
    )
}

export default LandingPageBody
