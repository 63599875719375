import React from "react";
import { AppBar, Toolbar, Typography, Stack } from "@mui/material";
import UploadProfilePicture from "./UploadProfilePicture";

const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: "#0e76a8", mb: 3 }}>
      <Toolbar>
        <Stack direction="row" spacing={2}>
          <UploadProfilePicture
            displayOnly={true}
            height={50}
            width={50}
          ></UploadProfilePicture>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LinkedIn Profile
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
