import React, { useState } from 'react';
import { Fab } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import FeedbackIcon from '@mui/icons-material/Feedback';

const styles = {
  fab: {
    position: 'fixed',
    bottom: 16,
    right: 16,
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
};

const FeedbackButton = () => {
  const feedbackUrl = 'https://forms.gle/jyn36erZEj2hynYj8';
  const [clicked, setClicked] = useState(false);

  const handleFeedbackClick = () => {
    window.open(feedbackUrl, '_blank');
    setClicked(true);
  };

  if (clicked) {
    return null;
  }

  return (
    <Fab style={styles.fab} onClick={handleFeedbackClick}>
      <FeedbackIcon />
    </Fab>
  );
};

export default FeedbackButton;
