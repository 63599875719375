import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import AddPng from '../../assets/landing-page/add.png';
import InventoryPng from '../../assets/landing-page/inventory.png';
import TrendPng from '../../assets/landing-page/trend.png';
import { useNavigate } from 'react-router-dom';

// todo: export to another file
const cards = [
    {
        id: 1,
        image: AddPng,
        title: 'Profile Generation',
        subtitle: 'Quickly craft a professional LinkedIn profile with ease'
    },
    {
        id: 2,
        image: InventoryPng,
        title: 'Skill Enhancement',
        subtitle: 'Boost your profile with the right skills and experience'
    },
    {
        id: 3,
        image: TrendPng,
        title: 'Networking',
        subtitle: 'Connect with industry professionals for career growth'
    }
];



export default function Album() {
    const navigate = useNavigate();
    const signUpOnClick = () => { navigate("/signup") }

    return (
        <>
            <main style={{ margin: 'auto' }}>
                <Container sx={{ py: 8 }} maxWidth="md">
                    <Grid container spacing={4}>
                        {cards.map((card) => (
                            <Grid item key={card.id} xs={12} sm={6} md={4}>
                                <Card sx={{ height: '200px', display: 'flex', flexDirection: 'column' }}>
                                    <CardMedia
                                        component="img"
                                        sx={{
                                            pt: '0%',
                                            width: '60px',
                                            height: '60px',
                                        }}
                                        image={card.image}
                                        alt={card.title}
                                    />
                                    <CardContent sx={{ flexGrow: 1 }}>
                                        <Typography gutterBottom variant="h5" component="h2" sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                                            {card.title}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ fontSize: '1rem' }}>
                                            {card.subtitle}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>
        </>
    );
}