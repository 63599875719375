export const ExperienceModel = {
  currentJobDescription: "",
  companyName: "",
  achievements: "",
  startDate: null,
  endDate: null,
  techSkills: [],
};

export const EducationModel = {
  studiesName: null,
  degreeName: null,
  significantCourses: "",
};

// fields via steps
export const personalStepFields = ["fullName", "aboutMe"];
export const professionalStepFields = ["jobExperience", "education"];
export const extraStepFields = ["extraCertificates", "volunteeringProjects"];
