import React from "react";

function BulletList({ text }) {
  const itemList = text
    .split("\n")
    .map((item, index) => <li key={index}>{item.trim()}</li>);

  return (
    <ul
      style={{
        marginLeft: "-10px",
        fontSize: "1.1rem",
        lineHeight: "1.5",
        marginTop: "1rem",
      }}
    >
      {itemList}
    </ul>
  );
}

export default BulletList;
