import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { EducationModel, ExperienceModel } from "../utils/models";
import { skills, topDegrees, topUniversities } from "../utils/options";
import CustomDialog from "./CustomDialog";
import LSKDatePicker from "./LSKDatePicker";
import LSKOptions from "./LSKOptions";
import LpcAutoComplete from "./LpcAutoComplete";
import LpcTexptField from "./LpcTexptField";

const ProfessionalStep = ({
  userProfile,
  setUserProfile,
  deleteSkillByIndex,
  handleCustomDialogAutoComplete,
  durationOfWork,
  handleToolSelected,
  errors,
  touch,
  setTouch,
}) => {
  const theme = useTheme();
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAddSection = (sectionType) => {
    setUserProfile(
      [
        ...userProfile[sectionType],
        sectionType === "jobExperience" ? ExperienceModel : EducationModel,
      ],
      sectionType
    );
  };

  const handleJobExpUpdate = (e, idx, typeToUpdate) => {
    const expCopy = cloneDeep(userProfile[typeToUpdate]);
    if (e.target) {
      expCopy[idx][e.target.name] = e.target.value;
    } else {
      const value = e.value;
      expCopy[idx][e.name] = value;
    }

    setUserProfile(expCopy, typeToUpdate);
  };

  const deleteExpByIdx = (idx, sectionType) => {
    const expCopy = cloneDeep(userProfile[sectionType]);
    expCopy.splice(idx, 1);

    setUserProfile(expCopy, sectionType);
  };

  return (
    <>
      {/* <BoxLpc> */}
      <h2>Current Job</h2>
      {userProfile.jobExperience.map((exp, idx) => (
        <Grid container alignItems="center" key={idx}>
          <Grid item sx={{ position: "absolute", left: 10 }} xs={1}>
            {userProfile.jobExperience.length > 1 && (
              <IconButton
                onClick={() => deleteExpByIdx(idx, "jobExperience")}
                disabled={userProfile.jobExperience.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item container sx={{ gap: 2 }}>
            <Grid item container>
              <LpcTexptField
                label="Job Description"
                fullWidth
                placeholder="Junior Software Developer"
                value={exp.currentJobDescription}
                name="currentJobDescription"
                onChange={(e) => handleJobExpUpdate(e, idx, "jobExperience")}
                error={
                  touch[`currentJobDescription[${idx}]`] &&
                  errors[`currentJobDescription[${idx}]`]
                }
                required
                onBlur={() =>
                  setTouch((old) => ({
                    ...old,
                    [`currentJobDescription[${idx}]`]: true,
                  }))
                }
                countDown={250}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ gap: 2 }}
              wrap={mobileMatches ? "wrap" : "nowrap"}
            >
              <Grid item xs={12} md={6}>
                <LpcTexptField
                  label="Company Name"
                  fullWidth
                  placeholder="Example: LPC"
                  value={exp.companyName}
                  name="companyName"
                  onChange={(e) => handleJobExpUpdate(e, idx, "jobExperience")}
                  error={
                    touch[`companyName[${idx}]`] &&
                    errors[`companyName[${idx}]`]
                  }
                  required
                  onBlur={() =>
                    setTouch((old) => ({
                      ...old,
                      [`companyName[${idx}]`]: true,
                    }))
                  }
                  countDown={20}
                />
              </Grid>
              <Grid item container sx={{ gap: 2 }} wrap="nowrap">
                <Grid item xs={6} md={6}>
                  <LSKDatePicker
                    fullWidth
                    value={exp.startDate}
                    label={"Start Date"}
                    onChange={(date) => {
                      handleJobExpUpdate(
                        { value: date, name: "startDate" },
                        idx,
                        "jobExperience"
                      );
                    }}
                    disableFuture
                    openTo="month"
                    views={["year", "month"]}
                    maxDate={exp.endDate}
                    closeOnSelect
                    onClose={() =>
                      setTouch((old) => ({
                        ...old,
                        [`startDate[${idx}]`]: true,
                      }))
                    }
                    error={
                      touch[`startDate[${idx}]`] && errors[`startDate[${idx}]`]
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <LSKDatePicker
                    fullWidth
                    value={exp.endDate}
                    label={"End Date"}
                    onChange={(date, untilNow = false) => {
                      if (untilNow) {
                        handleJobExpUpdate(
                          { value: untilNow, name: "isStillWork" },
                          idx,
                          "jobExperience"
                        );
                      } else {
                        handleJobExpUpdate(
                          { value: date, name: "endDate" },
                          idx,
                          "jobExperience"
                        );
                      }
                    }}
                    disableFuture
                    minDate={exp.startDate}
                    openTo="month"
                    views={["year", "month"]}
                    closeOnSelect
                    onClose={() =>
                      setTouch((old) => ({
                        ...old,
                        [`endDate[${idx}]`]: true,
                      }))
                    }
                    error={
                      touch[`endDate[${idx}]`] && errors[`endDate[${idx}]`]
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            {durationOfWork[idx] && (
              <Grid item container justifyContent="end">
                <Typography>
                  {`you've worked for: ${durationOfWork[idx]}`}
                </Typography>
              </Grid>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setToolsDialogOpen(idx)}
            >
              Add Work Skills +
            </Button>
            <LSKOptions
              arrToRender={exp.techSkills}
              onDelete={deleteSkillByIndex("jobExperience", "techSkills", idx)}
            />
            <CustomDialog
              title={"Select Skills You Worked With"}
              open={toolsDialogOpen === idx}
              onClose={() => setToolsDialogOpen(false)}
              elements={skills}
              selectedTools={exp.techSkills}
              handleElementSelected={(e) => handleToolSelected(e, idx)}
              onChangeAutoComplete={handleCustomDialogAutoComplete(
                "jobExperience",
                "techSkills",
                idx
              )}
              valueAutoComplete={exp.techSkills}
              placeholder={"Search Your Tech Skills Here"}
              label={"Your Tech Skills"}
            />
          </Grid>
          {userProfile.jobExperience.length !== 1 &&
            userProfile.jobExperience.length - 1 !== idx && (
              <Grid item xs={12} sx={{ marginBottom: 2, marginTop: 2 }}>
                <Divider />
              </Grid>
            )}
        </Grid>
      ))}
      <Grid container>
        <Tooltip
          title="Add Job Experience"
          arrow
          placement="right"
          leaveDelay={100}
        >
          <IconButton onClick={() => handleAddSection("jobExperience")}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <FormControlLabel
        label="I do not have job experience"
        control={
          <Checkbox
            checked={userProfile.isUnemployeed}
            onChange={(e) => setUserProfile(e, "isUnemployeed")}
            name="isUnemployeed"
          />
        }
      />
      {/* </BoxLpc> */}

      {/* <BoxLpc> */}
      <h2>Study</h2>
      {userProfile.education.map((edu, idx) => (
        <Grid container alignItems="center" key={idx}>
          <Grid
            item
            container
            alignItems="center"
            sx={{ gap: 2, position: "absolute", left: 10 }}
            xs={1}
          >
            {userProfile.education.length > 1 && (
              <IconButton
                onClick={() => deleteExpByIdx(idx, "education")}
                disabled={userProfile.education.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
          <Grid item container sx={{ gap: 2 }}>
            <Grid item xs={12} md={6}>
              {/* TODO: add to studiesName and degreeName a free text */}
              <LpcAutoComplete
                value={edu.studiesName}
                disablePortal
                options={topUniversities}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="University / College / Bootcamp / Self Learning"
                  />
                )}
                onChange={(e, value) =>
                  handleJobExpUpdate(
                    { name: "studiesName", value },
                    idx,
                    "education"
                  )
                }
                onClose={() =>
                  setTouch((old) => ({
                    ...old,
                    [`studiesName[${idx}]`]: true,
                  }))
                }
                error={
                  touch[`studiesName[${idx}]`] && errors[`studiesName[${idx}]`]
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LpcAutoComplete
                value={edu.degreeName}
                disablePortal
                options={topDegrees}
                renderInput={(params) => (
                  <TextField {...params} label="Degree" />
                )}
                onChange={(e, value) =>
                  handleJobExpUpdate(
                    { name: "degreeName", value },
                    idx,
                    "education"
                  )
                }
                onClose={() =>
                  setTouch((old) => ({
                    ...old,
                    [`degreeName[${idx}]`]: true,
                  }))
                }
                error={
                  touch[`degreeName[${idx}]`] && errors[`degreeName[${idx}]`]
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LpcTexptField
                label="Significant Courses"
                fullWidth
                placeholder="Introduction to Computer Science / 
                Data Structures and Algorithms / 
                Object-Oriented Programming"
                value={edu.significantCourses}
                name="significantCourses"
                onChange={(e) => handleJobExpUpdate(e, idx, "education")}
                error={
                  touch[`significantCourses[${idx}]`] &&
                  errors[`significantCourses[${idx}]`]
                }
                required
                onBlur={() =>
                  setTouch((old) => ({
                    ...old,
                    [`significantCourses[${idx}]`]: true,
                  }))
                }
                countDown={100}
              />
            </Grid>
            {userProfile.education.length !== 1 &&
              userProfile.education.length - 1 !== idx && (
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <Divider />
                </Grid>
              )}
          </Grid>
        </Grid>
      ))}
      <Grid container>
        <Tooltip title="Add Education" arrow placement="right" leaveDelay={100}>
          <IconButton onClick={() => handleAddSection("education")}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <FormControlLabel
        label="I do not have formal education"
        control={
          <Checkbox
            checked={userProfile.isNotEducate}
            onChange={(e) => setUserProfile(e, "isNotEducate")}
            name="isNotEducate"
          />
        }
      />
      {/* </BoxLpc> */}
    </>
  );
};

export default ProfessionalStep;
