import { Grid, LinearProgress } from "@mui/material";
import React from "react";
import LpcTexptField from "./LpcTexptField";

const ExtraStep = ({
  userProfile,
  setUserProfile,
  handleKeyDown,
  submitMsg,
  showLoader,
  errors,
  touch,
  setTouch,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LpcTexptField
          name="extraCertificates"
          error={touch.extraCertificates && errors.extraCertificates}
          onBlur={() =>
            setTouch((old) => ({ ...old, extraCertificates: true }))
          }
          countDown={510}
          onChange={(e) => setUserProfile(e, "extraCertificates")}
          value={userProfile.extraCertificates}
          placeholder="This is to certify that [Name] has successfully completed the [Course Name] course offered by [Organization] on [Date]."
          label="Extra Certificates"
          fullWidth
          multiline
          rows={4}
          onKeyDown={(e) => handleKeyDown(e, "extraCertificates")}
        />
      </Grid>
      <Grid item xs={12}>
        <LpcTexptField
          name="volunteeringProjects"
          error={touch.volunteeringProjects && errors.volunteeringProjects}
          onBlur={() =>
            setTouch((old) => ({ ...old, volunteeringProjects: true }))
          }
          countDown={510}
          onChange={(e) => setUserProfile(e, "volunteeringProjects")}
          value={userProfile.volunteeringProjects}
          placeholder="I volunteered at the [volunteer Institute] every Saturday for six months, helping with [activites]."
          label="Volunteering Projects"
          fullWidth
          multiline
          rows={4}
          onKeyDown={(e) => handleKeyDown(e, "volunteeringProjects")}
        />
        <h4>{submitMsg}</h4>
        {showLoader && <LinearProgress />}
      </Grid>
    </Grid>
  );
};

export default ExtraStep;
