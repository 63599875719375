import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const CustomDialog = ({
  open,
  onClose,
  elements,
  selectedTools,
  handleElementSelected,
  title,
  onChangeAutoComplete,
  label,
  placeholder,
  valueAutoComplete,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minHeight: "50vh" }}>
        <Autocomplete
          sx={{ margin: "8px 0 64px 0" }}
          value={valueAutoComplete}
          disablePortal
          options={elements}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder={placeholder} />
          )}
          onChange={(e, value) => {
            onChangeAutoComplete(value);
          }}
          multiple
          disableCloseOnSelect
          renderOption={(props, skill, { selected }) => {
            return (
              <li {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {skill}
              </li>
            );
          }}
        />

        <Typography>Top 5 Suggested Skills:</Typography>
        {elements.slice(0, 5).map((skill) => (
          <FormControlLabel
            key={skill}
            control={
              <Checkbox
                checked={selectedTools.includes(skill)}
                onChange={handleElementSelected}
                name={skill}
              />
            }
            label={skill}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
