import React from "react";
import LandingHeader from "../components/landingPage/LandingHeader";
import LandingPageBody from "../components/landingPage/LandingPageBody";
import Footer from "../components/landingPage/Footer";
import { Outlet } from "react-router-dom";

const LandingPage = () => {
  return (
    <div
      style={{ height: "-webkit-fill-available", backgroundColor: "#ebf5fc" }}
    >
      <LandingHeader />
      <LandingPageBody />
      <Footer />
      <Outlet />
    </div>
  );
};

export default LandingPage;
