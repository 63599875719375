import React from "react";
import { Button, Box, Typography, Snackbar } from "@mui/material";

const SuggestedPosts = (props) => {
  const { posts } = props;
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleGeneratePost = () => {
    setSnackbarOpen(true);
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5" component="h2" mb={2}>
        Posts Ideas
      </Typography>
      {posts.map((post) => (
        <Box
          key={post}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 2,
            mb: 2,
            flexWrap: "wrap",
          }}
        >
          <Typography variant="subtitle1" component="h3">
            {post}
          </Typography>
          <Button onClick={handleGeneratePost} variant="outlined">
            Generate Post
          </Button>
          <Snackbar
            open={snackbarOpen}
            message="Feature will soon be ready"
            onClose={() => setSnackbarOpen(false)}
            autoHideDuration={3000}
          />
        </Box>
      ))}
    </Box>
  );
};

export default SuggestedPosts;
