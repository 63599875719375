import { Box, Chip, Typography } from "@mui/material";

const ChipLpc = ({ data, noDataMsg, onDelete, setData }) => {
  // editMode - can delete by pressing x btn on the tag
  const deleteSkillByIndex = (idx) => {
    const skillsCopy = [...data];
    skillsCopy.splice(idx, 1);
    setData(skillsCopy);
  };
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        maxWidth: "100vw",
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {data && data.length > 0 ? (
        data.map((skill, idx) => (
          <Chip
            key={skill.name}
            label={skill.name}
            onDelete={onDelete ? () => deleteSkillByIndex(idx) : null}
          />
        ))
      ) : (
        <Typography>{noDataMsg}</Typography>
      )}
    </Box>
  );
};

export default ChipLpc;
