import { Box, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React from "react";

const LSKOptions = ({ arrToRender = [], onDelete }) => {
  // ALERT - onDelete should return a function for this component to work properly
  // because onDelete delete by index and there is no accessibility ti idx outside the component
  return (
    <>
      {arrToRender.map((option, idx) => (
        <Box
          key={idx}
          sx={{
            border: "1px solid #1E66C2",
            display: "flex",
            borderRadius: 14,
            padding: 1,
          }}
        >
          <>
            <Typography
              sx={{
                color: "#1E66C2",
                fontWeight: 500,
              }}
            >
              {option}
            </Typography>
            <IconButton
              sx={{ padding: 0 }}
              size="small"
              onClick={() => onDelete(idx)}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          </>
        </Box>
      ))}
    </>
  );
};

export default LSKOptions;
