import { useMediaQuery, useTheme } from "@material-ui/core";
import axios from "axios";
import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";

import { Button, Container, Grid } from "@mui/material";
import AboutSection from "../components/AboutSection";
import ExperienceSection from "../components/ExperienceSection";
import FeedbackButton from "../components/Feedback";
import Header from "../components/Header";
import HeadlineSection from "../components/HeadlineSection";
import EmailLink from "../components/MailTo";
import SkillsSection from "../components/SkillsSection";
import Suggestion from "../components/Suggestions";
import { COLORS } from "../consts/colors";
import { fakeParsedData } from "../utils/fakeData";
import EducationSection from "../components/EducationSection";
import { ProfileContext } from "../contexts/profileContextProvider";
import LoginPage from "./LinkedInAuth";

// todo: add buymecoffee in header
// todo: add option to edit inputs
// todo: add 'copy' for each section

const ProfilePage = () => {
  const { state } = useLocation();

  const theme = useTheme();
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));

  async function publishToLinkdin() {
    const res = await axios.get("/auth/oauth/linkedin");
    console.log(res.data);
  }

  const handleParsedData = () => {
    let parsedData = JSON.parse(state);

    if (!state) {
      parsedData = fakeParsedData;
    }

    // model completion for experionce prop for avoiding errors in ui console
    // TODO: export it to a func
    parsedData.data.experience = parsedData.data.experience.map((exp) => ({
      ...exp,
      startDate: exp.startDate
        ? typeof exp.startDate === "string"
          ? new Date(exp.startDate.split("/").reverse().join("-"))
          : exp.startDate
        : null,
      endDate: exp.endDate
        ? typeof exp.endDate === "string"
          ? new Date(exp.endDate.split("/").reverse().join("-"))
          : exp.endDate
        : null,
      description: exp.description || "",
    }));

    return parsedData;
  };

  const [parsedData, setParsedData] = useState(handleParsedData);
  const { isLogin, setIsLogin } = useContext(ProfileContext);

  if (!isLogin) return <LoginPage setIsLogin={setIsLogin}></LoginPage>;
  return (
    <div style={{ backgroundColor: COLORS.grey }}>
      <Header />
      <Container maxWidth="md">
        <Grid container>
          <HeadlineSection
            headline={parsedData.data.headLine}
            setData={setParsedData}
          />
          <AboutSection
            about={parsedData.data.about}
            topSkills={parsedData.data.skills}
            setData={setParsedData}
          />
          <SkillsSection
            topSkills={parsedData.data.skills}
            setData={setParsedData}
          />
          <ExperienceSection
            exp={parsedData.data.experience}
            setData={setParsedData}
          />
          <EducationSection
            education={parsedData.data.education}
            setData={setParsedData}
          />
          <Suggestion
            posts={parsedData.data.postsIdeas}
            connections={parsedData.data.connections}
          />
          <Grid
            container
            justifyContent={mobileMatches ? "center" : "space-between"}
            sx={{ mb: 5 }}
          >
            <Button
              disabled
              variant="contained"
              color="success"
              onClick={publishToLinkdin}
              sx={mobileMatches ? { mb: 5 } : {}}
            >
              connect To LinkedIn
            </Button>
            <EmailLink />
          </Grid>
        </Grid>
        <FeedbackButton />
      </Container>
    </div>
  );
};

export default ProfilePage;
