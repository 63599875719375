import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useDurationOfWork } from "../hooks/useDurationOfWork";
import ChipLpc from "./ChipLpc";
import LSKDatePicker from "./LSKDatePicker";
import CustomDialog from "./CustomDialog";
import { skills } from "../utils/options";

// todo: make description in bullets
const ExperienceItem = ({
  title,
  company,
  startDate,
  endDate,
  description,
  editExpMode,
  setEditExpData,
  deleteExp,
  disabledDelete,
  data,
}) => {
  const [toolsDialogOpen, setToolsDialogOpen] = useState(false);
  const durationOfWork = useDurationOfWork(startDate, endDate);

  // editMode - pick skills while pressing on the checkboxes in CustomDialog
  const handleSkillSelected = (event) => {
    const skillName = event.target.name;
    const newSelectedSkills = event.target.checked
      ? [...data.techSkills, { name: skillName, rating: 5 }]
      : data.techSkills.filter((skill) => skill.name !== skillName);
    setEditExpData({
      target: { name: "techSkills", value: newSelectedSkills },
    });
  };

  // editMode - find the rating value cause CustomDialog hanling only simple arrays and not arrays of objects
  const findRating = (skillName) => {
    const result = data.techSkills.find((obj) => obj.name === skillName);
    if (result) {
      return result.rating;
    } else {
      return "1";
    }
  };

  // editMode - AutoComplete handler - if select new skill - it will generate the rating with value of "1"
  const handleCustomDialogAutoComplete = (value) => {
    const formatSkills = value.map((skillName) => ({
      name: skillName,
      rating: findRating(skillName),
    }));
    setEditExpData({
      target: { name: "techSkills", value: formatSkills },
    });
  };

  // formatting the complex array of objects to a simple array so the CustomDialog will work properly
  const skillsWithNoRating = useMemo(
    () => data.techSkills.map((skill) => skill.name),
    [data.techSkills]
  );

  const handlerDeleteChip = (skills) => {
    setEditExpData({
      target: {
        name: "techSkills",
        value: skills,
      },
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      {!editExpMode ? (
        <>
          <Typography variant="h6" component="h3" mb={1}>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" mb={1}>
            {`Company - ${company}`}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" mb={2}>
            {`Work for - ${durationOfWork}`}
          </Typography>
          <Typography variant="body1" color="text.secondary" mb={2}>
            {description}
          </Typography>
          <ChipLpc data={data.techSkills} noDataMsg="No Skills" />
        </>
      ) : (
        <Grid container alignItems="center">
          <Grid item container alignItems="center" sx={{ gap: 2 }} xs={11}>
            <TextField
              label="Title"
              name="title"
              value={title}
              fullWidth
              onChange={setEditExpData}
              required
              inputProps={{ maxLength: 50 }}
            />
            <TextField
              label="Company"
              name="companyName"
              value={company}
              fullWidth
              onChange={setEditExpData}
              required
              inputProps={{ maxLength: 50 }}
            />

            <Grid item container sx={{ gap: 2 }} wrap="nowrap">
              <LSKDatePicker
                fullWidth
                value={startDate}
                label={"Start Date"}
                onChange={(date) => setEditExpData({ date, name: "startDate" })}
                disableFuture
                openTo="month"
                views={["year", "month"]}
                closeOnSelect
              />
              <LSKDatePicker
                fullWidth
                value={endDate}
                label={"End Date"}
                onChange={(date) => setEditExpData({ date, name: "endDate" })}
                disableFuture
                minDate={startDate}
                openTo="month"
                views={["year", "month"]}
                closeOnSelect
              />
            </Grid>

            <TextField
              label="Description"
              name="description"
              value={description}
              fullWidth
              onChange={setEditExpData}
              required
              inputProps={{ maxLength: 50 }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setToolsDialogOpen(true)}
            >
              Skills +
            </Button>
            <CustomDialog
              title={"Your Personal/Tech Skills"}
              open={toolsDialogOpen}
              onClose={() => setToolsDialogOpen(false)}
              elements={[...skills]}
              selectedTools={skillsWithNoRating}
              handleElementSelected={handleSkillSelected}
              onChangeAutoComplete={handleCustomDialogAutoComplete}
              valueAutoComplete={skillsWithNoRating}
              placeholder={"Search yout Tech Skills Here"}
              label={"Your Tech Skills"}
            />
            <ChipLpc
              data={data.techSkills}
              noDataMsg="No Tech Skills"
              onDelete
              setData={handlerDeleteChip}
            />
          </Grid>
          <Grid item container alignItems="center" sx={{ gap: 2 }} xs={1}>
            <IconButton onClick={deleteExp} disabled={disabledDelete}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ExperienceItem;
