import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../contexts/profileContextProvider";

import {
  Button,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import MoreInfo from "../components/MoreInfo";

import { cloneDeep } from "lodash";
import ExtraStep from "../components/ExtraStep";
import LpcMessageDialog from "../components/LpcMessageDialog";
import PersonalStep from "../components/PersonalStep";
import ProfessionalStep from "../components/ProfessionalStep";
import { useDurationOfWorkArr } from "../hooks/useDurationOfWork";
import {
  activeStepToErrorsStep,
  inputValidations,
  pressedNextValidationError,
} from "../validations/inputValidations";
import LoginPage from "./LinkedInAuth";

function InputPage() {
  const navigate = useNavigate();
  const { profilePicture, isLogin, setIsLogin } = useContext(ProfileContext);
  const [activeStep, setActiveStep] = useState(0);
  const [submitMsg, setSubmitMsg] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [touch, setTouch] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState({
    personalErrors: {},
    professionalErrors: {},
    extraErrors: {},
  });
  const [userProfile, setUserProfile] = useState({
    jobExperience: [
      {
        currentJobDescription: "",
        companyName: "",
        startDate: null,
        endDate: null,
        techSkills: [],
        isStillWork: false,
      },
    ],
    isUnemployeed: false,
    education: [
      {
        studiesName: null,
        degreeName: null,
        significantCourses: "",
      },
    ],
    isNotEducate: false,
    fullName: "",
    aboutMe: "",
    extraCertificates: "",
    volunteeringProjects: "",
    personalSkills: [],
  });

  const [pressedError, setPressedError] = useState(false);

  useEffect(() => {
    inputValidations(userProfile, setErrors, {
      isUnemployeed: userProfile.isUnemployeed,
      isNotEducate: userProfile.isNotEducate,
    });
  }, [userProfile, isLogin]);

  const handleNext = () => {
    if (pressedNextValidationError(userProfile)) {
      setPressedError(true);
    } else setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  const handleSetUserProfile = (e, fieldName) => {
    if (e.target) {
      e.target.type !== "checkbox"
        ? setUserProfile({ ...userProfile, [fieldName]: e.target.value })
        : setUserProfile({ ...userProfile, [fieldName]: e.target.checked });
    } else {
      // no event sent as parameter from AutoComplete Component and LSKDatePicker but value instead
      const value = e;
      setUserProfile({ ...userProfile, [fieldName]: value });
    }
  };

  const handleKeyDown = (e, fieldName) => {
    if (
      // e.keyCode - right arrow
      (e.key === "Tab" || e.keyCode === 39) &&
      !userProfile[fieldName].includes(e.target.placeholder) &&
      userProfile[fieldName] === ""
    ) {
      e.preventDefault();
      setUserProfile({
        ...userProfile,
        [fieldName]: e.target.placeholder,
      });
    }
  };

  const durationOfWork = useDurationOfWorkArr(userProfile.jobExperience);

  const handleToolSelected = (event, idx) => {
    const toolName = event.target.name;
    const newSelectedTools = event.target.checked
      ? [...userProfile.jobExperience[idx].techSkills, toolName]
      : userProfile.jobExperience[idx].techSkills.filter(
          (tool) => tool !== toolName
        );

    const jobExpCopy = [...userProfile.jobExperience];
    jobExpCopy[idx] = { ...jobExpCopy[idx], techSkills: newSelectedTools };

    setUserProfile((prevProfile) => ({
      ...prevProfile,
      jobExperience: jobExpCopy,
    }));
  };

  const handleSkillSelected = (event) => {
    const skillName = event.target.name;
    const newselectedSkills = event.target.checked
      ? [...userProfile.personalSkills, skillName]
      : userProfile.personalSkills.filter((skill) => skill !== skillName);

    setUserProfile((prevProfile) => ({
      ...prevProfile,
      personalSkills: newselectedSkills,
    }));
  };

  const deleteSkillByIndex =
    (sectionType, skillType, parentArrIdx) => (idx) => {
      const skillsCopy = [...userProfile[sectionType]];
      if (skillType && parentArrIdx >= 0) {
        skillsCopy[parentArrIdx][skillType].splice(idx, 1);
      } else {
        // TODO: change the order of the params for sake of completenece
        // for personalSkills which is not section but skillType
        skillsCopy.splice(idx, 1);
      }

      setUserProfile((prevProfile) => ({
        ...prevProfile,
        [sectionType]: skillsCopy,
      }));
    };

  const handleCustomDialogAutoComplete =
    (sectionType, skillType, idx) => (value) => {
      let copySection = cloneDeep(userProfile[sectionType]);
      if (skillType && idx >= 0) {
        copySection[idx][skillType] = value;
      } else {
        // TODO: change the order of the params for sake of completenece
        // for personalSkills which is not section but skillType
        copySection = value;
      }
      handleSetUserProfile(copySection, sectionType);
    };

  async function handleSubmit() {
    setSubmitMsg("Creating Your LinkedIn Profile...");
    setShowLoader(true);
    //include profile picture and work duration
    const responseData = {
      ...userProfile,
      profilePicture,
      workDuration: durationOfWork,
    };

    try {
      const response = await axios.post("/api/profile", responseData);
      if (response && response.status === 200) {
        let res = JSON.stringify(response.data);
        navigate("/profile", { state: res });
      }
    } catch (error) {
      setSubmitMsg("Something Went Wrong :(");
      setShowLoader(false);
      console.error(error);
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <PersonalStep
            userProfile={userProfile}
            setUserProfile={handleSetUserProfile}
            handleKeyDown={handleKeyDown}
            deleteSkillByIndex={deleteSkillByIndex}
            handleCustomDialogAutoComplete={handleCustomDialogAutoComplete}
            handleSkillSelected={handleSkillSelected}
            errors={errors.personalErrors}
            touch={touch}
            setTouch={setTouch}
          />
        );
      case 1:
        return (
          <ProfessionalStep
            userProfile={userProfile}
            setUserProfile={handleSetUserProfile}
            deleteSkillByIndex={deleteSkillByIndex}
            handleCustomDialogAutoComplete={handleCustomDialogAutoComplete}
            durationOfWork={durationOfWork}
            handleToolSelected={handleToolSelected}
            errors={errors.professionalErrors}
            touch={touch}
            setTouch={setTouch}
          />
        );
      case 2:
        return (
          <ExtraStep
            userProfile={userProfile}
            setUserProfile={handleSetUserProfile}
            handleKeyDown={handleKeyDown}
            submitMsg={submitMsg}
            showLoader={showLoader}
            errors={errors.extraErrors}
            touch={touch}
            setTouch={setTouch}
          />
        );
      default:
        return null;
    }
  };
  if (!isLogin) return <LoginPage setIsLogin={setIsLogin}></LoginPage>;

  return (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        style={{ fontWeight: "bold", marginTop: 10 }}
      >
        LinkedIn Profile Creator
      </Typography>
      <Stepper
        style={{ width: "80vw", margin: "0 auto", marginTop: "40px" }}
        activeStep={activeStep}
      >
        <Step>
          <StepLabel>Personal</StepLabel>
        </Step>
        <Step>
          <StepLabel>Professional</StepLabel>
        </Step>
        <Step>
          <StepLabel>Extra</StepLabel>
        </Step>
      </Stepper>
      <div style={{ margin: "60px", marginTop: "2rem" }}>
        {activeStep === 3 ? (
          <div>
            <Typography variant="h5" gutterBottom>
              Thank you for submitting!
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            {getStepContent(activeStep)}
            <div style={{ marginTop: "2rem" }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{ marginRight: "1rem" }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                onClick={activeStep === 2 ? handleSubmit : handleNext}
                disabled={
                  Object.keys(errors[activeStepToErrorsStep[activeStep]])
                    .length > 0
                }
              >
                {activeStep === 2 ? "Submit" : "Next"}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Snackbar
        open={showSnackbar}
        message="Please enter required fields"
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <LpcMessageDialog
        open={pressedError}
        onClose={() => setPressedError(false)}
        title="Add Personal skills error"
        message="For continue the proccess, please pick at list one skill"
      />
      <MoreInfo />
      {/** the "MoreInfo" component should be in the end of the file for some reasone it mess up the file render after login
       * TODO: find the source problem and fix it
       */}
    </div>
  );
}

export default InputPage;
